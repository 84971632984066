import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import logo from "../../images/01.svg";
import menu from "../../images/Menu.svg";
import cross from "../../images/cross.svg";
import instagram from "../../images/instagram.svg";
import facebook from "../../images/facebook.svg";
import twitter from "../../images/twitter.svg";
import linkedin from "../../images/linkedin.svg";
import tiktok from "../../images/tiktok.svg";
import arrow from "../../images/arrow.svg";
import styles from "./navbar.module.css";

function Navbar() {
  const { i18n, t } = useTranslation();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isSocialMediaExpanded, setIsSocialMediaExpanded] = useState(false);

  const handleNavExpand = () => {
    setIsNavExpanded(!isNavExpanded);
  };

  const handleSocialMediaExpanded = () => {
    setIsSocialMediaExpanded(!isSocialMediaExpanded);
  };

  return (
    <nav className={styles.navContainer}>
      <div className={`${styles.nav} container`}>
        <HashLink to="/#home">
          <img
            className={
              isNavExpanded ? `${styles.hide} ${styles.logo}` : styles.logo
            }
            src={logo}
            alt="KITO logo"
          />
        </HashLink>

        <div className={styles.menu}>
          {/* {!isNavExpanded && (<button className={styles.downloadBtn}><p>{t('navBar.button')}</p></button>)} */}
          <button className={styles.burgerBtn} onClick={handleNavExpand}>
            {isNavExpanded ? (
              <img width={32} src={cross} alt="close icon" />
            ) : (
              <img width={32} src={menu} alt="menu icon" />
            )}
          </button>
        </div>
        <div
          className={
            isNavExpanded
              ? `${styles.navMenu} ${styles.expanded}`
              : styles.navMenu
          }
        >
          <ul>
            <li>
              <HashLink to="/#features" onClick={handleNavExpand}>
                <p>{t("navBar.li1")}</p>
              </HashLink>
            </li>
            <li>
              <HashLink to="/#howItWorks" onClick={handleNavExpand}>
                <p>{t("navBar.li2")}</p>
              </HashLink>
            </li>
            <li>
              <HashLink to="/#about" onClick={handleNavExpand}>
                <p>{t("navBar.li3")}</p>
              </HashLink>
            </li>
            <li>
              <HashLink to="/#contact" onClick={handleNavExpand}>
                <p>{t("navBar.li4")}</p>
              </HashLink>
            </li>
            <li
              className={`${styles.navCollapseSocialMediaToggle}${
                isSocialMediaExpanded
                  ? " " + styles.navIsSocialMediaExpanded
                  : ""
              }`}
              onClick={handleSocialMediaExpanded}
            >
              <p>Social media</p>
              <img src={arrow} />
            </li>
            <li>
              <ul
                className={`${styles.navSocialMediaOptions}${
                  isSocialMediaExpanded ? " " + styles.navShowSocialMedia : ""
                }`}
              >
                <li>
                  <a
                    href="https://www.instagram.com/kito_health"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p>
                      <img src={instagram} alt="instagram icon" />
                      <span className={styles.navSocialMediaName}>
                        Instagram
                      </span>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/p/Kito-Health-100068762685787/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p>
                      <img src={facebook} alt="facebook icon" />
                      <span className={styles.navSocialMediaName}>
                        Facebook
                      </span>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/kitohealth/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p>
                      <img src={linkedin} alt="linkedin icon" />
                      <span className={styles.navSocialMediaName}>
                        LinkedIn
                      </span>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@kitohealthapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p>
                      <img src={tiktok} alt="tiktok icon" />
                      <span className={styles.navSocialMediaName}>TikTok</span>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="https://x.com/Kito_Health"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p>
                      <img src={twitter} alt="X icon" />
                      <span className={styles.navSocialMediaName}>
                        X (Twitter)
                      </span>
                    </p>
                  </a>
                </li>
              </ul>
            </li>
            {/* <li><button className={`${styles.navMenuBtn} ${styles.downloadBtn}`}><p>{t('navBar.button')}</p></button></li> */}
            <li>
              <button onClick={() => i18n.changeLanguage("Swahili")}>
                <p className={i18n.language === "Swahili" ? "bold" : ""}>
                  {t("navBar.language1")}
                </p>
              </button>
              <span> | </span>
              <button onClick={() => i18n.changeLanguage("English")}>
                <p className={i18n.language === "English" ? "bold" : ""}>
                  {t("navBar.language2")}
                </p>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
