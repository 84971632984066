import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../images/footerlogo.svg";
import instagram from "../../images/instagramDark.svg";
import facebook from "../../images/facebookDark.svg";
import twitter from "../../images/twitterDark.svg";
import linkedin from "../../images/linkedinDark.svg";
import tiktok from "../../images/tiktokDark.svg";
import styles from "./footer.module.css";

function Footer() {
  const { t } = useTranslation();
  const date = new Date().getFullYear();

  return (
    <footer>
      <img src={logo} className={styles.footer__logo} alt="logo" />
      <ul className={styles.footer__links}>
        <li>
          <Link target="_blank" to="/terms">
            <p>{t("footer.li1")}</p>
          </Link>
        </li>
        <li>
          <Link target="_blank" to="/policy">
            <p>{t("footer.li2")}</p>
          </Link>
        </li>
      </ul>

      <ul className={styles.footer__socials}>
        <li>
          <a
            href="https://www.instagram.com/kito_health"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="instagram icon" />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/p/Kito-Health-100068762685787/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook icon" />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/kitohealth/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="linkedin icon" />
          </a>
        </li>
        <li>
          <a
            href="https://www.tiktok.com/@kitohealthapp"
            target="_blank"
            rel="noreferrer"
          >
            <img src={tiktok} alt="tiktok icon" />
          </a>
        </li>
        <li>
          <a href="https://x.com/Kito_Health" target="_blank" rel="noreferrer">
            <img src={twitter} alt="X icon" />
          </a>
        </li>
      </ul>
      <p className="small">
        &copy; {date}
        {t("footer.p")}
      </p>
    </footer>
  );
}

export default Footer;
